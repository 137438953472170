import { ComponentType, ReactNode } from 'react'
import { isFragment } from 'react-is'
import { compact, isArray, isEmpty } from 'lodash'

const getDisplayName = (Component: ComponentType<any>) =>
  Component.displayName || Component.name || 'Component'

const isEmptyFragment = (instance: ReactNode) => {
  if (!isFragment(instance)) return false

  const children = instance.props?.children

  if (isArray(children)) return isEmpty(compact(children))

  return !children
}

export { getDisplayName, isFragment, isEmptyFragment }
